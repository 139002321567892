div#app {
  height: 100vh;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}

.grecaptcha-badge {
  bottom: 45px !important;
  z-index: 9999;
}
